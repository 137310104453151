<script setup lang="ts">
const props = defineProps<{
  content: string;
}>();

const router = useRouter();
const rteEl = shallowRef<HTMLElement | null>(null);
let _links!: HTMLCollectionOf<HTMLAnchorElement> | undefined;

onMounted(async () => {
  try {
    await nextTick();
    addListeners();
  } catch (error) {
    const loggingService = useLoggingService();
    loggingService.error(error);
  }
});

onBeforeUnmount(() => {
  removeListeners();
});

const navigate = (event: MouseEvent) => {
  let target = event.target as Node;
  let i = 0;

  // Go through 5 parents max to find a tag
  while (i < 5 && target instanceof Node && !(target instanceof HTMLAnchorElement) && target.parentNode) {
    target = target.parentNode;
    i++;
  }

  if (!(target instanceof HTMLAnchorElement)) {
    return;
  }

  const href = handleRelativeUrl(target.getAttribute('href') || '');
  const linkTarget = target.getAttribute('target');

  // Get link target, if local link, navigate with router link
  if (href && href !== '' && href[0] === '/' && linkTarget !== '_blank') {
    event.preventDefault();

    router.push(href);
  }
};

const addListeners = () => {
  _links = rteEl.value?.getElementsByTagName('a');

  if (!_links) {
    return;
  }

  for (let i = 0; i < _links.length; i++) {
    _links[i]?.addEventListener('click', navigate, false);
  }
};

const removeListeners = () => {
  if (!_links) {
    return;
  }

  for (let i = 0; i < _links.length; i++) {
    _links[i]?.removeEventListener('click', navigate, false);
  }

  _links = undefined;
};

const strippedRichText = computed(() => {
  let stripped = props.content;

  // Remove all div tags
  stripped = stripped?.replace(/<div\b[^>]*>(.*?)<\/div>/gi, '$1');

  // Append website base to all image src
  stripped = stripped?.replace(/src="\/media\//g, `src="${useCdnBase('')}/media/`);

  // Append website base to all link href
  stripped = stripped?.replace(/href="\/media\//g, `href="${useCdnBase('')}/media/`);

  return stripped;
});
</script>

<template>
  <div
    ref="rteEl"
    class="rte-content"
    v-html="strippedRichText"
  />
</template>
